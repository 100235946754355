import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  AboutSectionContainer,
  ExternalLink,
} from '@product-site-frontend/shared';
import { Link as GatsbyLink } from 'gatsby';
import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import useFetch from 'use-http';

import { DocumentsAccordion, DocumentsList } from './DisclosureSectionHelpers';
import {
  DISCLOSURE_LINK,
  DOC_PANELS,
  PAYMENT_DETAILS,
} from './DisclosureSectionHelpers/constants';
import Styled from './SectionInfoDisclosure.styled';

SwiperCore.use([Pagination, Navigation]);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel({ children, index, value, ...rest }) {
  return (
    <div {...rest} hidden={value !== index} style={{ width: '100%' }}>
      {value === index && (
        <Box sx={{ px: { xs: 3, lg: 9 } }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function SectionInfoDisclosure() {
  const [documents, setDocuments] = useState({});

  const { get, loading, response } = useFetch(
    `${process.env.GATSBY_FRONTOFFICE_API}/about_page_documents`,
    { cachePolicy: 'no-cache' },
  );

  const handleFetchDocuments = useCallback(async () => {
    await get();
    if (response.ok) {
      const { data } = response;

      const docs = {};

      data.forEach((doc) => {
        const category = String(doc.category_key).trim();
        docs[category] = [...(docs[category] || []), doc];
      });

      setDocuments(docs);
    }
  }, [get, response]);

  useEffect(() => handleFetchDocuments(), [handleFetchDocuments]);

  const [activeTabIdx, setActiveTabIdx] = useState(0);
  const handleChangeTab = useCallback((_, newValue) => {
    setActiveTabIdx(newValue);
  }, [setActiveTabIdx]);

  const theme = useTheme();
  const matchesAdaptive = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    matchesAdaptive ? (
      <noindex>
        <AboutSectionContainer sx={{
          "& .swiper-container": {
            "& .swiper-button-prev, & .swiper-button-next": {
              top: "14px"
            },
          },
        }}>
          <Swiper
            centeredSlide={true}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            roundLengths
            slidesPerView={1}
            spaceBetween={24}
          >
            <SwiperSlide
              index={1}
              key={`panel-qweqweqwe`}
            >
              <Typography sx={{ ...Styled.TabTitle, padding: "0 60px" }}>Раскрытие информации</Typography>

              <Typography
                color="primary"
                component={ExternalLink}
                fontWeight="500"
                href={`https://${DISCLOSURE_LINK}`}
              >
                {DISCLOSURE_LINK}
              </Typography>
            </SwiperSlide>

            <SwiperSlide
              index={2}
              key={`panel-asdqaweqwsas`}
            >
              <Typography sx={{ ...Styled.TabTitle, padding: "0 60px" }}>Реквизиты для расчетов</Typography>

              <Box>
                <Typography sx={{ fontSize: 22, fontWeight: 700, mb: 4 }}>
                  Т-Банк
                </Typography>
                {PAYMENT_DETAILS.map(({ label, value }) => (
                  <Typography
                    key={label}
                    sx={{ fontSize: 18, fontWeight: 700, mb: 2 }}
                  >
                    {label}{' '}
                    <Typography fontWeight="300" variant="text">
                      {value}
                    </Typography>
                  </Typography>
                ))}
              </Box>
            </SwiperSlide>

            {DOC_PANELS.map(({ category, title }, idx) => (
              <SwiperSlide
                index={idx + 2}
                key={`panel-${category}`}
              >
                <Typography sx={{ ...Styled.TabTitle, padding: "0 60px" }}>{title}</Typography>

                {documents?.[category]?.every(({ subcategory }) => subcategory !== "")
                  ? (
                    <DocumentsAccordion
                      altText={title}
                      category={category}
                      documents={documents?.[category]}
                      isLoading={loading}
                    />
                  ) : (
                    <DocumentsList
                      documents={documents?.[category]}
                      isLoading={loading}
                    />
                  )}
              </SwiperSlide>
            ))}
          </Swiper>
        </AboutSectionContainer>
      </noindex>
    ) : (
      <AboutSectionContainer title="Раскрытие информации">
        <Box sx={!matchesAdaptive ? { flexGrow: 1, display: "flex" } : {}}>
          <Tabs
            onChange={handleChangeTab}
            orientation={matchesAdaptive ? "horizontal" : "vertical"}
            sx={matchesAdaptive ? Styled.TabsAdaptive : Styled.Tabs}
            value={activeTabIdx}
            variant="scrollable"
          >
            <Tab label="Адрес страницы раскрытия информации" sx={Styled.Tab} />
            <Tab label="Реквизиты для расчетов" sx={Styled.Tab} />
            {DOC_PANELS.map(({ category, title }) => (
              <Tab key={`tab-${category}`} label={title} sx={Styled.Tab} />
            ))}
          </Tabs>
          <Box sx={{ width: "100%", pt: { xs: 2, lg: 0 } }}>
            <TabPanel index={0} value={activeTabIdx}>
              <Typography sx={{ ...Styled.TabTitle, mb: 4, textAlign: "left" }}>
                Раскрытие информации
              </Typography>
              <Typography
                color="primary"
                component={ExternalLink}
                fontWeight="500"
                href={`https://${DISCLOSURE_LINK}`}
              >
                {DISCLOSURE_LINK}
              </Typography>
            </TabPanel>

            <TabPanel index={1} value={activeTabIdx}>
              <Typography sx={{ ...Styled.TabTitle, mb: 4, textAlign: 'left' }}>
                Реквизиты для расчетов
              </Typography>
              <Typography sx={{ fontSize: 22, fontWeight: 700, mb: 4 }}>
                Т-Банк
              </Typography>
              {PAYMENT_DETAILS.map(({ label, value }) => (
                <Typography
                  key={label}
                  sx={{ fontSize: 18, fontWeight: 700, mb: 2 }}
                >
                  {label}{' '}
                  <Typography fontWeight="300" variant="text">
                    {value}
                  </Typography>
                </Typography>
              ))}
            </TabPanel>

            {DOC_PANELS.map(({ category, title, }, idx) => (
              <TabPanel
                index={idx + 2}
                key={`panel-${category}`}
                value={activeTabIdx}
              >
                <Typography sx={Styled.TabTitle}>{title}</Typography>
                {documents?.[category]?.every(({ subcategory }) => subcategory !== '')
                  ? (
                    <DocumentsAccordion
                      altText={title}
                      category={category}
                      documents={documents?.[category]}
                      isLoading={loading}
                    />
                  ) : (
                    <DocumentsList
                      documents={documents?.[category]}
                      isLoading={loading}
                    />
                  )}
              </TabPanel>
            ))}
          </Box>
        </Box>
      </AboutSectionContainer>
    )
  );
}